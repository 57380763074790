import React from 'react';

class Footer extends React.Component {


		constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (

				<div id="this-footer"><h5>JR Handyman LLC</h5><h5><a href="tel:+15803199792">(580) 319-9792</a></h5></div>

		       )
	}
}
export default Footer;
