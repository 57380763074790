import React from 'react';

class Jrlogo extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}


	render() {
		return (
				<div className={this.props.className} id={this.props.id}></div>
		       )
	}
}
export default Jrlogo;
