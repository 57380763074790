import React from 'react';
import Header from '../base/header';
import Jrlogo from '../base/jrlogo';
import JRHCarousel from '../base/carousel';
import Footer from '../base/footer';

class Home extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-1">

						</div>
						<div className="col-sm-10 col-md-10 col-lg-10" id="logo-container">
							<Jrlogo className="jrLogoStyleHome" />
						</div>
						<div className="col-sm-1 col-md-1 col-lg-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1"></div>
						<div className="col-sm-10">

							<h2 className="bootstrap-overrides" id="service_area">Serving Ardmore, OK and the surrounding cities</h2>
							<br />

							<h2 className="bootstrap-overrides homeH2">Welcome to JR Handyman!</h2>


							<p>
								We do anything from small repairs to remodeling and working with drywall, floors and more!
							</p>

							<p>
								Investing in home repairs and improvements can easily add up to the total value of the property,
								plus it gives it that special and unique touch that makes it feeling it more like home!
							</p>

							<br />

							<h2 className="bootstrap-overrides homeH2">Why should I hire JR Handyman for my repairs and improvements?</h2>
							<ul id="why-us">
								<li>Professional and friendly people who understand your needs</li>
								<li>We have some years of experience working with projects</li>
								<li>We truly value your business, time and trust you deposit in us!</li>
							</ul>


						</div>
						<div className="col-sm-1"></div>
					</div>
					<br></br>
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-2 col-xl-3">

						</div>
						<div className="col-sm-10 col-md-10 col-lg-8 col-xl-6">
							<JRHCarousel />
							<br />
							<br />
							<br />
							<h4 className="bootstrap-overrides" id="operationHours">JR Handyman LLC, Ardmore OK 73401</h4>
						</div>
						<div className="col-sm-1 col-md-1 col-lg-2 col-xl-3">
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}
export default Home;
