import React from 'react';
import Header from '../base/header';
import Jrlogo from '../base/jrlogo';
import Footer from '../base/footer';

class Services extends React.Component {

	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1">

						</div>
						<div className="col-sm-10" id="logo-container">
							<Jrlogo className="jrLogoStyleHome" />
						</div>
						<div className="col-sm-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1 col-md-1 col-lg-1"></div>
						<div className="col-12 col-sm-10 col-md-10 col-lg-10">
							<p>
								With JR Handyman you can always expect friendliness and professionalism in all of our services we offer.
							</p>

							<h2 className="bootstrap-overrides" id="contentH2">We offer the following services: </h2>
							<br></br>

							<div className="service_container">
								<div>
									<div className="services_images s_i_left" id="services_images_drywall"></div>
								</div>
								<div className="services_r services_description s_d_right" id="services_r_drywall">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">New drywall installation and repairs</h4>
										<p>
											Whether you want to repair some cracks or dents in your current walls, remodel certain areas in a particular room or 
											even build an addition to your house we got you covered. We will work on your drywall, apply texture and paint it back to
											the matching color! We do the finishing from the floors all the way up to the ceiling.
								</p>
									</div>
								</div>
							</div>

							<div className="service_container">
								<div>

									<div className="services_images s_i_right" id="services_images_repairs"></div>
								</div>
								<div className="services_r services_description s_d_left" id="services_r_g_repairs">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">General repairs and finishing</h4>
										<p>
											Did you need a door lock repaired? Are you having issues with pipe leaks? Or maybe you think that old lamp fixture needs to be replaced?

											Regardless of how small or big a repair might seem to you we can help you doing it!

											We can also repaint and apply stain to your wooden doors and fences and work with all the 
											exterior of your house to give it that unique and special touch!
									</p>
									</div>
								</div>
							</div>

							<div className="service_container">
								<div>
									<div className="services_images s_i_left" id="services_images_floors"></div>
								</div>
								<div className="services_r services_description s_d_right" id="services_r_floors">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">Floor repairs and new installation</h4>
										<p>
											An unleveled floor simply doesn't look good and may cause other issues. We can level up your floors by replacing the boards underneath, install new 
											plywood and the flooring style you decide. We have experience with vinyl, wooden and ceramic floors!
										 </p>
									</div>
								</div>
							</div>

							<div className="service_container" id="last_service">
								<div>
									<div className="services_images s_i_right" id="services_images_tile"></div>
								</div>
								<div className="services_r services_description s_d_left" id="services_r_tile">
									<div className="responsive_background">
										<h4 className="bootstrap-overrides" id="contentH4">Tile works</h4>
										<p>
											Is your kitchen or bathroom having that old and stained looking? If your tile looks old 
											or you just want to give it a fresh look we can help you out! We work with tile and remodel kitchens and
											bathrooms, doing the leveling and preparing the area to set the new tile and making sure it will look great!
									</p>
									</div>
								</div>
							</div>
							<br></br>
							<br></br>
							<div className="service_container">
								<h4 className="bootstrap-overrides" id="contentH4">Did we forget to mention something? Please contact JR Handyman LLC with any question you have!</h4>
								<br />
								<br />
								<br />
							</div>
						</div>
						<div className="col-sm-1 col-md-1 col-lg-1"></div>
					</div>
				</div>
				<Footer />

			</div>
		)
	}
}
export default Services;
