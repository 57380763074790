import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../media/1.jpg';
import img2 from '../../media/2.jpg';
import img3 from '../../media/3.jpg';
import img5 from '../../media/5.jpg';
import img6 from '../../media/6.jpg';

class JRHCarousel extends Component {
	render() {
		return (
				<Carousel className="carousel-custom-style" showThumbs={false} showStatus={false} autoPlay interval={4000} infiniteLoop>
					<div>
					<img src={img1} />
					<p className="legend">Bathroom remodeling</p>
					</div>
 
					<div>
					<img src={img2} />
					<p className="legend">Floors</p>
					</div>

					<div>
					<img src={img3} />
					<p className="legend">Sinks</p>
					</div> 

				 	<div>
					<img src={img5} />
					<p className="legend">Tile works</p>
					</div>  

					<div>
					<img src={img6} />
					<p className="legend">Home exteriors</p>
					</div> 
				</Carousel>
		       );
	}
};

export default JRHCarousel;
