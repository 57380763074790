import React from 'react';
import axios from 'axios';
import Header from '../base/header';
import Jrlogo from '../base/jrlogo';
import Footer from '../base/footer';
var Recaptcha = require('react-recaptcha');
let recaptchaInstance;


class Contact extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isVerified: false,
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			comment: '',
			recaptchaValue: ''

		};
		this.onloadCallback = this.onloadCallback.bind(this);
		this.processReCaptcha = this.processReCaptcha.bind(this);
		this.verifyCallback = this.verifyCallback.bind(this);
		this.getFirstName = this.getFirstName.bind(this);
		this.getLastName = this.getLastName.bind(this);
		this.getEmail = this.getEmail.bind(this);
		this.getPhone = this.getPhone.bind(this);
		this.getComment = this.getComment.bind(this);
	}

	getFirstName = event => {
		this.setState({ firstName: event.target.value });
	}

	getLastName = event => {
		this.setState({ lastName: event.target.value });
	}

	getEmail = event => {
		this.setState({ email: event.target.value });
	}
	getPhone = event => {
		this.setState({ phone: event.target.value });
	}

	getComment = event => {
		this.setState({ comment: event.target.value });
	}

	verifyCallback(response) {
		if (response) {
			this.setState({
				isVerified: true,
				recaptchaValue: response
			})
			document.getElementById("status-message").innerText = ""
			//axios.post('http://localhost:3000/submitForm/recaptchaHelper', {
				axios.post('https://yoboapps-agassiz.com/jrhandymanok/submitForm/recaptchaHelper', {
				gRecaptcha: response
			})
				.then(function (response) {
				})
				.catch(function (error) {
					console.log("Error in captcha");
				});

		}
	}

	onloadCallback() {
	}





	processReCaptcha() {
		if (this.state.isVerified) {
			if (this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '' && this.state.comment !== '') {
			
					axios.post('https://yoboapps-agassiz.com/jrhandymanok/submitForm', {
					firstName: this.state.firstName,
					lastName: this.state.lastName,
					email: this.state.email,
					phone: this.state.phone,
					comment: this.state.comment,
					gRecaptcha: this.state.recaptchaValue
				})
					.then(function (response) {
						document.getElementById("status-message").className = "message-success";
						document.getElementById("status-message").innerText = "Thank you! We will get back with you as soon as possible"


					})
					.catch(function (error) {
						document.getElementById("status-message").innerText = "Please verify your information and try again"
						document.getElementById("status-message").className = "message-error";
					});
				recaptchaInstance.reset();
				this.setState({
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					comment: '',
					gRecaptcha: '',
					isVerified: false

				});
			}
			else {
				document.getElementById("status-message").innerText = "Please make sure you have provided enough information to contact you back and try again"
				document.getElementById("status-message").className = "message-error";
			}

		}
		else {
			document.getElementById("status-message").className = "message-error";
			document.getElementById("status-message").innerText = "Please resolve the reCAPTCHA before submitting your information"
		}


	}

	render() {

		return (
			<div>
				<Header />
				<div className="container-fluid" id="top-container-fluid">
					<div className="row">
						<div className="col-sm-1">

						</div>
						<div className="col-sm-10" id="logo-container">
							<Jrlogo className="jrLogoStyleHome" />
						</div>
						<div className="col-sm-1">

						</div>
					</div>
					<div className="row">
						<div className="col-sm-1 col-md-1"></div>
						<div className="col-sm-10 col-md-10">

							<h4 className="bootstrap-overrides">In JR Handyman we believe communication is very important, please send us any comments or questions using the form below and we will get back with you as soon as possible!</h4>
							<br></br>


						</div>
						<div className="col-sm-1 col-md-1"></div>

					</div>
					<div className="row">
						<div className="col-sm-1 col-md-1"></div>
						<div className="col-sm-10 col-md-10">

							<div>
								<form>
									<div className="form-group">
										<input className="form-control" type="text" placeholder="Your first name" value={this.state.firstName} onChange={this.getFirstName} />
									</div>
									<div className="form-group">
										<input type="text" className="form-control" placeholder="Your last name" value={this.state.lastName} onChange={this.getLastName} />
									</div>
									<div className="form-group">
										<input type="email" className="form-control" placeholder="Your e-mail address" value={this.state.email} onChange={this.getEmail} />
									</div>
									<div className="form-group">
										<input type="tel" className="form-control" placeholder="Your phone number" value={this.state.phone} onChange={this.getPhone} />
									</div>
									<div className="form-group">
										<textarea rows="8" className="form-control" placeholder="Please provide your inquiry or comments" value={this.state.comment} onChange={this.getComment}></textarea>
									</div>


									<Recaptcha sitekey="6LeR4NgZAAAAALvdOW0ulg0_k2o9IzqHd3a2JZqc" ref={e => recaptchaInstance = e} render="explicit" verifyCallback={this.verifyCallback} onloadCallback={this.onloadCallback} />
									<h4 id="status-message" className=""></h4>
									<input type="button" value="Submit" onClick={this.processReCaptcha} />

								</form>
								<br />
								<br />
								<br />
{/* 								<h4 className="bootstrap-overrides" id="operationHours">Our operation hours are Monday-Friday from 8:00 AM to 5:00 PM and from 8:00 AM to 12:00 PM on Saturdays</h4>
 */}
							</div>
						</div>
						<div className="col-sm-1 col-md-1"></div>
					</div>

				</div>
				<Footer />

			</div>
		)
	}
}
export default Contact;
